<template>
  <div class="py-6" style="background: linear-gradient(270deg, #9D4EFF 0%, #782AF4 100%);">
  </div>
  <!-- Page header -->
  <div class="bg-white shadow-sm">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
          <div class="d-md-flex align-items-center justify-content-between bg-white  pt-3 pb-3 pb-lg-5">
            <div class="d-md-flex align-items-center text-lg-start text-center ">
              <div class="me-3  mt-n8">
                <router-link :to="{name: 'CourseDetail', params: {code: course.code}}" :title="course.name">
                  <img :src="course.thumbnail ? course.thumbnail.link : '/assets/images/avatar/avatar.jpg'" :alt="course.name"
                       class="avatar-xxl rounded bg-white object-fit-cover">
                </router-link>
              </div>
              <div class="mt-3 mt-md-0">
                <h1 class="mb-0 fw-bold me-3 ">{{ course.name }}</h1>
                <div>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{
                      course.lesson_count
                    }}</span> bài giảng</span>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{
                      course.submits.paginatorInfo.total
                    }}</span> bài nộp</span>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{ course.teachers.length }}</span> giáo viên</span>
                  <span class="ms-2 fs-6"><span class="text-dark fw-medium">{{
                      course.users.paginatorInfo.total
                    }} </span> học viên</span>
                </div>
              </div>
            </div>
            <div class="mt-3 mt-lg-0 text-lg-start text-center">
              <button v-if="tab === 'user'" data-bs-toggle="modal" data-bs-target="#addUserCourseModal"
                      class="btn btn-primary float-end text-nowrap"><i class="fe fe-plus-circle"></i> Thêm học viên
              </button>
            </div>
          </div>
          <!-- Nav tab -->
          <ul class="nav nav-lt-tab" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a v-on:click="setTab('user')" :class="tab === 'user' ? 'nav-link active' : 'nav-link'"
                 id="pills-user-tab" data-bs-toggle="pill" href="#pills-user" role="tab"
                 aria-controls="pills-user" aria-selected="false">Học viên</a>
            </li>
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('submit')" :class="tab === 'submit' ? 'nav-link active' : 'nav-link'"
                 id="pills-submit-tab" data-bs-toggle="pill" href="#pills-submit" role="tab"
                 aria-controls="pills-submit" aria-selected="true">Bài nộp</a>
            </li>
            <li class="nav-item ms-0" role="presentation">
              <a v-on:click="setTab('share')" :class="tab === 'share' ? 'nav-link active' : 'nav-link'"
                 id="pills-share-tab" data-bs-toggle="pill" href="#pills-share" role="tab"
                 aria-controls="pills-share" aria-selected="true">Share khóa học</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Content  -->
  <div class="py-4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <!-- Tab content -->
          <div class="tab-content" id="pills-tabContent">
            <div :class="tab === 'submit' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-submit"
                 role="tabpanel" aria-labelledby="pills-submit-tab">
              <div class="col-md-12">
                <SubmitLesson v-on:dataChange="loadCourse"
                              v-on:currentPageChange="currentPage => {course.submits.paginatorInfo.currentPage = currentPage; loadCourse()}"
                              v-on:filterChange="filter => {submitFilter = filter; course.submits.paginatorInfo.currentPage = 1; loadCourse()}"
                              :data="course.submits.data"
                              :paginatorInfo="course.submits.paginatorInfo"/>
              </div>
            </div>
            <div :class="tab === 'user' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-user"
                 role="tabpanel" aria-labelledby="pills-user-tab">
              <div class="col-md-12">
                <div class="card pb-4">
                  <div class="card-header border-bottom-0">
                    <form class="d-flex align-items-center">
                      <input type="search" v-model="userFilter.name" class="form-control me-4" placeholder="Họ tên">
                      <input type="search" v-model="userFilter.email" class="form-control me-4" placeholder="Email">
                      <input type="search" v-model="userFilter.phone" class="form-control me-4" placeholder="SĐT">
                    </form>
                  </div>
                  <table class="table mb-4 text-nowrap list">
                    <thead class="table-light">
                    <tr>
                      <th scope="col">Họ tên</th>
                      <th scope="col">Email</th>
                      <th scope="col">SĐT</th>
                      <th scope="col">Hoàn thành (%)</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(user, index) in course.users.data" :key="'user' + index">
                      <td class="align-middle">
                        <div class="d-lg-flex align-items-center">
                          <div class="thumbnail">
                            <router-link :to="{name: 'AdminEditUser', params: {uid: user.uid}}">
                              <img :src="user.thumbnail ? user.thumbnail.link : '/assets/images/avatar/avatar.jpg'"
                                   alt="" class="avatar">
                            </router-link>
                          </div>
                          <div class="ms-lg-3 mt-2 mt-lg-0">
                            <h4 class="mb-1 text-primary-hover">
                              <router-link class="text-inherit" :to="{name: 'AdminEditUser', params: {uid: user.uid}}">
                                {{ user.name }}
                              </router-link>
                            </h4>
                          </div>
                        </div>
                      </td>
                      <td class="align-middle">
                        {{ user.email }}
                      </td>
                      <td class="align-middle">
                        {{ user.phone }}
                      </td>
                      <td class="align-middle">
                        <div class="progress" style="height: 6px;">
                          <div class="progress-bar bg-success" role="progressbar"
                               :style="'width: ' + user.userCoursePivot.percent + '%;'"
                               :aria-valuenow="user.userCoursePivot.percent" aria-valuemin="0"
                               aria-valuemax="100"></div>
                        </div>
                        <small>{{ user.userCoursePivot.percent }}% hoàn thành</small>
                      </td>
                      <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="userDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <a class="dropdown-item" v-on:click="setCurrentUser(user)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeUserCourseModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                              </span>
                            </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <v-pagination
                      v-if="course.users.paginatorInfo.lastPage > 1"
                      v-model="course.users.paginatorInfo.currentPage"
                      :pages="course.users.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadCourse"
                  />
                </div>
              </div>
            </div>
            <div :class="tab === 'share' ? 'tab-pane fade show active' : 'tab-pane fade'" id="pills-share"
                 role="tabpanel" aria-labelledby="pills-share-tab">
              <div class="col-md-12">
                <div class="card pb-4">
                  <div class="card-header border-bottom-0">
                    <form class="d-flex align-items-center">
                      <input type="search" v-model="schoolFilter.name" class="form-control me-4" placeholder="Tên trường">
                    </form>
                  </div>
                  <table class="table mb-4 text-nowrap list">
                    <thead class="table-light">
                    <tr>
                      <th scope="col">Trường học</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(school, index) in schools.data" :key="'school' + index">
                      <td class="align-middle">
                        <div class="form-check">
                          <input v-if="school.isShared" :disabled="school.isOwner" v-model="school.isShared" v-on:click="removeSchoolCourse(school)" class="form-check-input" type="checkbox">
                          <input v-if="!school.isShared" v-model="school.isShared" v-on:click="addSchoolCourse(school)" class="form-check-input" type="checkbox">
                          <label class="form-check-label">
                            {{ school.name }}
                          </label>&nbsp;
                          <span v-if="school.isOwner" class="badge bg-danger">Chủ sở hữu</span>
                        </div>
                      </td>
                      <td class="text-muted align-middle border-top-0"></td>
                    </tr>
                    </tbody>
                  </table>
                  <v-pagination
                      v-if="schools.paginatorInfo.lastPage > 1"
                      v-model="schools.paginatorInfo.currentPage"
                      :pages="schools.paginatorInfo.lastPage"
                      :range-size="1"
                      active-color="#DCEDFF"
                      @update:modelValue="loadSchools"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeUserCourseModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Xóa học viện khỏi khóa học
          </h4>
          <button type="button" id="removeUserCourseModalClose" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc muốn xóa <span class="fw-bold">{{ currentUser.name }}</span> khỏi khóa học?</p>
          <div class="d-flex justify-content-center">
            <button class="btn btn-danger" type="button"
                    v-on:click="removeUserCourse">
              Xóa học viên
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="addUserCourseModal" tabindex="-1" role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">
            Thêm học viên vào khóa học
          </h4>
          <button type="button" id="addUserCourseModalClose" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>

        <div class="modal-body">
          <vue-select close-on-select
                      class="w-100 mb-4"
                      :options="addUserCourseUsers"
                      :visible-options="addUserCourseVisibleOptions"
                      v-model="addUserCourseUID"
                      searchPlaceholder="Gõ để tìm kiếm"
                      label-by="name"
                      value-by="uid"
                      track-by="name"
                      searchable
                      clear-on-select
                      v-on:search:input="addUserCourseHandleSearchInput"
          ></vue-select>
          <div class="d-flex justify-content-center">
            <button class="btn btn-primary" type="button"
                    v-on:click="addUserCourse">
              Thêm học viên
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import Modal from "@/components/Modal";
import ApiService from "@/core/services/api.service";
import {closeModal, getWhereCondition, openModal} from "@/core/services/utils.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import VueNextSelect from 'vue-next-select';
import 'vue-next-select/dist/index.min.css'
import SubmitLesson from "@/components/SubmitLesson";

export default {
  name: "CourseStatistic",
  components: {
    Modal,
    'vue-select': VueNextSelect,
    VPagination,
    SubmitLesson
  },
  data() {
    return {
      course: {
        name: "",
        thumbnail: null,
        users: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        },
        submits: {
          data: [],
          paginatorInfo: {
            currentPage: 1,
            lastPage: 0,
            total: 0
          }
        },
        schools: {
          data: []
        },
        teachers: []
      },
      schools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: "",
      userFilter: {
        name: "",
        email: "",
        phone: ""
      },
      submitFilter: {
        name: "",
        email: "",
        phone: "",
        lesson_name: "",
        is_reviewed: ""
      },
      schoolFilter: {
        name: ""
      },
      tab: "user",
      currentUser: {
        name: "",
        uid: 0
      },
      addUserCourseUsers: [],
      addUserCourseUID: null,
      addUserCourseSearchInput: "",
      sharedSchools: [],
      ownerSchools: []
    }
  },
  methods: {
    loadCourse() {
      let userWhere = this.getUserWhereCondition();
      let submitWhere = this.getSubmitWhereCondition();

      let query = `query($code: String!) {
        course(code: $code) {
          name
          code
          section_count
          lesson_count
          thumbnail {
            link
          }
          users(first: 5, page: ${this.course.users.paginatorInfo.currentPage} ${userWhere}) {
            data {
              uid
              name
              email
              phone
              userCoursePivot {
                percent
              }
              thumbnail {
                link
              }
            },
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
          submits(first: 5, page: ${this.course.submits.paginatorInfo.currentPage} ${submitWhere}) {
            data {
              id
              lesson_name
              section_name
              comment
              is_reviewed
              reviewer {
                name
                thumbnail {
                  link
                }
              }
              user {
                uid
                name
                email
                phone
                thumbnail {
                  link
                }
              }
              media {
                link
                media_type
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
          schools(first: 10000, page: 1) {
            data {
              id
              code
              courseSchoolPivot {
                is_owner
              }
            }
          }
          teachers {
            uid
            name
            thumbnail {
              link
            }
          }
        }
      }`;

      ApiService.graphql(query, {code: this.$route.params.code})
          .then(({data}) => {
            if (data.data && data.data.course) {
              this.course = data.data.course;
              this.loadSchools();
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadUsers() {
      let query = `query {
        mySchool {
          users(first: 1000, where: {AND: [{column:"school_user.role", value:"pupil"}]}) {
            data {
              name
              phone
              email
              uid
            }
          }
        }
      }`;

      ApiService.graphql(query, {code: this.$route.params.code})
          .then(({data}) => {
            if (data.data && data.data.mySchool && data.data.mySchool.users && data.data.mySchool.users.data) {
              this.addUserCourseUsers = data.data.mySchool.users.data;
              this.addUserCourseUsers.map((user) => {
                if (user.phone) {
                  user.name = user.name + " - " + user.phone;
                }
                if (user.email) {
                  user.name = user.name + " - " + user.email;
                }
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadSchools() {
      let where = this.getSchoolWhereCondition();

      let query = `query {
        schools(first: 5, page: ${this.schools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]${where}) {
          data {
            id
            code
            name
            subdomain
            status
            created_at
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.schools = data.data.schools;
              if (this.schools.data && this.schools.data.length) {
                this.schools.data.map((school) => {
                  school.isShared = this.schoolIsShared(school.code);
                  school.isOwner = this.schoolIsOwner(school.id);
                });
              }
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    addUserCourse() {
      this.messageModalTitle = "Thêm học viên";

      let query = `mutation($uid: String!, $course_code: String!) {
        addUserCourse(uid: $uid, course_code: $course_code)
      }`;

      ApiService.graphql(query, {uid: this.addUserCourseUID, course_code: this.course.code})
          .then(({data}) => {
            if (data.data && data.data.addUserCourse) {
              this.messageModalMessage = "Thêm học viên thành công";
              this.messageModalType = "success";
              closeModal("addUserCourseModal");
              openModal("messageModal");
              this.loadCourse();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("addUserCourseModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("addUserCourseModal");
            openModal("messageModal");
          });
    },
    getUserWhereCondition() {
      let userFilters = {};

      for (let key in this.userFilter) {
        if (key === "name" || key === "email" || key === "phone") {
          if (this.userFilter[key] !== "") {
            userFilters[key] = {
              operator: "LIKE",
              value: "%" + this.userFilter[key] + "%"
            }
          }
        }
      }

      return getWhereCondition(userFilters);
    },
    getSubmitWhereCondition() {
      let filters = {};

      for (let key in this.submitFilter) {
        if (key === "name" || key === "email" || key === "phone" || key === "lesson_name") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.submitFilter[key] + "%"
            }
          }
        } else if (key === "is_reviewed") {
          if (this.submitFilter[key] !== "") {
            filters[key] = {
              operator: "EQ",
              value: this.submitFilter[key]
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    getSchoolWhereCondition() {
      let filters = {};

      for (let key in this.schoolFilter) {
        if (key === "name") {
          if (this.schoolFilter[key] !== "") {
            filters[key] = {
              operator: "LIKE",
              value: "%" + this.schoolFilter[key] + "%"
            }
          }
        }
      }

      return getWhereCondition(filters);
    },
    removeUserCourse() {
      this.messageModalTitle = "Xóa học viên khỏi khóa học";

      let query = `mutation($uid: String!, $course_code: String!) {
        removeUserCourse(uid: $uid, course_code: $course_code)
      }`;

      ApiService.graphql(query, {uid: this.currentUser.uid, course_code: this.course.code})
          .then(({data}) => {
            if (data.data && data.data.removeUserCourse) {
              this.messageModalMessage = "Xóa học viên khỏi khóa học thành công";
              this.messageModalType = "success";
              closeModal("removeUserCourseModal");
              openModal("messageModal");
              this.loadCourse();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("removeUserCourseModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("removeUserCourseModal");
            openModal("messageModal");
          });
    },
    setCurrentUser(user) {
      this.currentUser = user;
    },
    addUserCourseHandleSearchInput(event) {
      this.addUserCourseSearchInput = event.target.value;
    },
    setTab(tab) {
      this.tab = tab;
    },
    schoolIsShared(school_code) {
      return this.sharedSchools.includes(school_code);
    },
    schoolIsOwner(school_id) {
      return this.ownerSchools.includes(parseInt(school_id));
    },
    addSchoolCourse(school) {
      this.messageModalTitle = "Share khóa học";

      let query = `mutation($school_code: String!, $course_code: String!) {
        addSchoolCourse(school_code: $school_code, course_code: $course_code)
      }`;

      ApiService.graphql(query, {school_code: school.code, course_code: this.course.code})
          .then(({data}) => {
            if (data.data && data.data.addSchoolCourse) {
              this.messageModalMessage = "Share khóa học thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              school.isShared = true;
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
              school.isShared = false;
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
            school.isShared = false;
          });
    },
    removeSchoolCourse(school) {
      this.messageModalTitle = "Share khóa học";

      let query = `mutation($school_code: String!, $course_code: String!) {
        removeSchoolCourse(school_code: $school_code, course_code: $course_code)
      }`;

      ApiService.graphql(query, {school_code: school.code, course_code: this.course.code})
          .then(({data}) => {
            if (data.data && data.data.removeSchoolCourse) {
              this.messageModalMessage = "Bỏ share khóa học thành công";
              this.messageModalType = "success";
              school.isShared = false;
              openModal("messageModal");
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              school.isShared = true;
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            school.isShared = true;
            openModal("messageModal");
          });
    },
    getSharedSchools() {
      this.sharedSchools = [];
      this.ownerSchools = [];
      if (this.course.schools.data && this.course.schools.data.length) {
        let schools = this.course.schools.data;
        for (let i = 0; i < schools.length; i++) {
          this.sharedSchools.push(schools[i].code);
          if (schools[i].courseSchoolPivot.is_owner === 1) {
            this.ownerSchools.push(parseInt(schools[i].id));
          }
        }
      }
    }
  },
  computed: {
    addUserCourseVisibleOptions() {
      let re = new RegExp(this.addUserCourseSearchInput);
      return this.addUserCourseUsers.filter(option => re.test(option.name));
    }
  },
  mounted() {
    this.loadCourse();
    this.loadUsers();
  },
  watch: {
    userFilter: {
      handler() {
        this.course.users.paginatorInfo.currentPage = 1;
        this.loadCourse();
      },
      deep: true
    },
    schoolFilter: {
      handler() {
        this.schools.paginatorInfo.currentPage = 1;
        this.loadSchools();
      },
      deep: true
    },
    "course.schools.data": {
      handler() {
        this.getSharedSchools();
      },
      deep: true
    }
  }
}
</script>
